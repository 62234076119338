
import React, {useState, useContext} from 'react'
import InputMask from 'react-input-mask';
import { images } from '../helpers'
import {Context} from '../context'

class PhoneInput extends React.Component {
  render() {
    // return <InputMask {...this.props} mask="(---) --- -- --" maskChar=" " />;
    return <InputMask {...this.props} mask="+3\8 (099) 999 99 99" maskChar=" " />;
  }
}

const Apply = (props) => {

  const [phoneNum, setPhoneNum] = useState('');
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    phone: '',
    restaurantName: '',
    errors: {
      nameErr: '',
      emailErr: '',
      phoneErr: ''
    },
    nameValid: false,
    emailValid: false,
    phoneValid: false,
    formValid: false,
    submitionThanksPopupIsShown: false,
    isUserAgreed: false,
  });



  const handleSubmit = (event) => {
    event.preventDefault();

    if(validateForm(formState.errors) && formState.isUserAgreed) {
      setFormState({
        ...formState,
        submitionThanksPopupIsShown: true
      })

      submitRequestForTrustSourcing(); // submitRequestForHubspot() triggers inside;

      function submitRequestForTrustSourcing(){
        let xhr = new XMLHttpRequest();
        xhr.open('GET', 'https://emailsubscription.trustsourcing.com/SubscribeTomato?' + `email=${formState.email}&phone=${formState.phone}&name=${formState.name}&restaurantName=${formState.restaurantName}`);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.send({
          name: formState.name,
          email: formState.email,
          restaurantName: formState.restaurantName,
          phone: formState.phone
        });
        xhr.onload = function() {
          if (xhr.status != 200) { // analyze HTTP status of the response
            // console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
          } else { // show the result
            // console.log(`Done, got ${xhr.response.length} bytes`); // responseText is the server
            submitRequestForHubspot();
            props.setIsFormOpened(false);
            props.setIsThanksMessageOpened(true);
          }
        };

        xhr.onprogress = function(event) {
          if (event.lengthComputable) {
            // console.log(`Received ${event.loaded} of ${event.total} bytes`);
          } else {
            // console.log(`Received ${event.loaded} bytes`); // no Content-Length
          }
        };

        xhr.onerror = function() {
          alert("Request failed");
        };
      }

      function submitRequestForHubspot(){
        let data = {
          "contactFields": {
              "email": formState.email,
              "firstName": formState.name,
              "phone": formState.phone
          },
          "formValues": {
              "Назва ресторану": formState.restaurantName
          },
          "labelToNameMap": {
              "Назва ресторану": formState.restaurantName
          },
          "portalId": 5070333,
          "uuid": "843620b5-a1d8-4781-88f5-3b9ab6d8ca85"
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://forms.hubspot.com/collected-forms/submit/form', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(data));
        xhr.onload = function() {
          if (xhr.status != 200) { // analyze HTTP status of the response
            // console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
          } else { // show the result
            // console.log(`Done, got ${xhr.response.length} bytes`); // responseText is the server
          }
        };

        xhr.onprogress = function(event) {
          if (event.lengthComputable) {
            // console.log(`Received ${event.loaded} of ${event.total} bytes`);
          } else {
            // console.log(`Received ${event.loaded} bytes`); // no Content-Length
          }
        };

        xhr.onerror = function() {
          alert("Request failed");
        };
      }


    } else{
      console.warn('Invalid Form, please make it properly')
    }
  }

  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  const handlechange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = formState.errors;

    const validEmailRegex =
      RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    switch (name) {
      case 'email':
        errors.emailErr =
          validEmailRegex.test(value)
            ? ''
            : 'Это поле является обязательным для заполнения';
        break;
      case 'phone':
        setPhoneNum(value);
        break;
      default:
        break;
    }

    // console.log('phoneNum: ', phoneNum);
    // console.log('formState: ', formState);
    // console.log('emailValid: ', formState.emailValid);

    setFormState({
      ...formState,
      errors,
      [name]: value
    })
  }

  const handleCheckbox = () => {
    if (formState.isUserAgreed === false){
      setFormState({...formState, isUserAgreed: true});
    } else {
      setFormState({...formState, isUserAgreed: false});
    }
  }

  const formClasses = `
    apply
    ${props.isFormOpened ? 'isOpened' : ''}
  `;

  const emailClasses = `
    apply__item
    ${formState.errors.emailErr ? 'apply__item--error' : ''}
  `;

  const checkboxClasses = `
    apply__checkbox
    ${ formState.isUserAgreed ? 'apply__checkbox--checked' : '' }
  `;

  return (
    <div className={formClasses}>
      <div className="apply__overlay" />


      <div className="apply__essence">
        <div onClick={()=>{props.setIsFormOpened(false)}} className="apply__close">
          <img
            src={images.misc.close}
          />
        </div>


        <div className="apply__title">
          Отправьте заявку сейчас и получите
          доступ&nbsp;для второго ресторана!
        </div>

          <form className="apply__form" onSubmit={handleSubmit}>
            <fieldset>

              <div className="apply__item">
                <label className="apply__label" htmlFor="uName">Имя</label>
                <input
                  onChange={handlechange}
                  className="apply__input"
                  id="uName"
                  name="name"
                  type="text"
                  placeholder="Введите пожалуйста имя"
                />
              </div>

              <div className="apply__item">
                <label className="apply__label" htmlFor="restaurantName">Название ресторана</label>
                <input
                  onChange={handlechange}
                  className="apply__input"
                  id="restName"
                  name="restaurantName"
                  type="text"
                  placeholder="Введите название ресторана"
                />
              </div>

              <div className={emailClasses}>
                <label className="apply__label" htmlFor="email">Електронная почта</label>
                <input
                  onChange={handlechange}
                  className="apply__input"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Введите email"
                />
                <div className="apply__message">{formState.errors.emailErr ? formState.errors.emailErr : ''}</div>
              </div>



              <div className="apply__item">
                <label className="apply__label" htmlFor="num">Телефон</label>
                <PhoneInput
                  onChange={handlechange}
                  value={formState.phone}
                  placeholder="(---) --- -- --"
                  className="apply__input"
                  name="phone"
                />
              </div>

              <div
                onClick={handleCheckbox}
                className="apply__item  apply__item--checkbox">
                <div className={checkboxClasses} />
                <label
                  className="apply__label  apply__label--checkbox"
                  htmlFor="remember">
                  {/* <input className="apply__checkbox" id="remember" type="checkbox" />  */}
                  Я даю согласие на использование моих персональных данных
                </label>
              </div>



                <button type="submit" className="apply__button">
                  <span>Отправить</span>
                </button>

                {/* <button onClick={()=>{props.setIsThanksMessageOpened(true)}}>thx msg</button> */}

            </fieldset>
          </form>

      </div>
    </div>
  )
}

export default Apply

const images = {
  header: {
    logo: require('../assets/images/logo.svg'),
    bg: require('../assets/images/bg.png'),
    fb: require('../assets/images/socials__facebook.svg'),
    insta: require('../assets/images/socials__insta.svg')
  },
  misc: {
    tomatoMan: require('../assets/images/tomato-unit.svg'),
    close: require('../assets/images/close.svg')
  }
};

export default images;


import React from 'react'
import { images } from '../helpers'

const ThanksPopup = (props) => {

  const thxContainerClasses = `
    thx  
    ${props.isThanksMessageOpened ? 'isOpened' : ''}
  `;

  return (
    <div className={thxContainerClasses}>
      <div className="thx__overlay" />

      <div className="thx__essence">
        <div onClick={()=>{props.setIsThanksMessageOpened(false)}} className="thx__close">
          <img
            src={images.misc.close}
          />
        </div>
        <div className="thx__title">
          Спасибо за обращение!<br/>
          Мы свяжемся с вами в ближайшее время
        </div>
        <img
          className="thx__tomato-man"
          src={images.misc.tomatoMan}
        />

        <a href="https://mrtomato.io" target="_blank" className="thx__button">
          <span>Посетить сайт mrtomato.io</span>
        </a>

        <div className="thx-more">
          <div className="thx-more__text">
            Узнайте также больше о <b>Mr. Tomato</b>
          </div>
          <div className="thx-more__socials">
            <a
              href="https://www.instagram.com/mr.tomato_official/?hl=uk"
              target="_blank"
              className="header-socials__item"
            >
              <img
                src={images.header.insta}
                alt="Instagram"
                width="40px"
                height="40px"
              />
            </a>
            <a
              href="https://www.facebook.com/mistertomato/"
              target="_blank"
              className="header-socials__item"
            >
              <img
                src={images.header.fb}
                alt="Facebook"
                width="40px"
                height="40px"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThanksPopup

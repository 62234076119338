
import React from 'react'
import { images } from '../helpers'

const Header = () => {

  return (
    <div className="header">
      <div className="container  container--header">
        <a
          href="/"
          className="header__logo"
        >
          <img
            src={images.header.logo}
            alt="MrTomato"
          />
        </a>

        <div className="header-socials">
          <a
            href="https://www.instagram.com/mr.tomato_official/?hl=uk"
            target="_blank"
            className="header-socials__item"
          >
            <img
              src={images.header.insta}
              alt="Instagram"
            />
          </a>
          <a
            href="https://www.facebook.com/mistertomato/"
            target="_blank"
            className="header-socials__item"
          >
            <img
              src={images.header.fb}
              alt="Facebook"
            />
          </a>
        </div>


        {/* <div className="wrap xl-3 xl-gutter-16 xl-flexbox xl-top lg-top xl-between lg-flexbox lg-center md-1">

            <div className="col xl-1-3 lg-1-2  advantages__item">
              <img
                src={images.advantages.elem01}
                alt="Инновационность"
              />
            </div>
        </div> */}

      </div> {/* EOF .container */}
    </div>
  )
}

export default Header

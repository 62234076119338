import React, {useState, useEffect, useContext} from 'react'
import { images } from '../helpers'
import {Context} from '../context'

let interval = undefined;

const Timer = (props) => {
  const [days, setDays] = useState(null);
  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [seconds, setSeconds] = useState(null);

  const [daysText, setDaysText] = useState(null);
  const [hoursText, setHoursText] = useState(null);
  const [minutesText, setMinutesText] = useState(null);
  const [secondsText, setSecondsText] = useState(null);

  const countDownDate = new Date("Dec 20, 2019 23:59:59").getTime();

  const calcTimerData = () => {
    const distance = countDownDate - new Date().getTime();
    setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
    setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
  };

  function renderSwitch(){
    switch(days){
      case 2:
      case 3:
      case 4:
      case 22:
      case 23:
      case 24:
      case 32:
      case 33:
      case 34:
      case 42:
      case 43:
      case 44:
      case 52:
      case 53:
      case 54:
        setDaysText('Дня');
        break;
      case 1:
      case 21:
      case 31:
      case 41:
      case 51:
        setDaysText('День');
        break;
      default:
        setDaysText('Дней');
    }
    switch(hours){
      case 2:
      case 3:
      case 4:
      case 22:
      case 23:
      case 24:
      case 32:
      case 33:
      case 34:
      case 42:
      case 43:
      case 44:
      case 52:
      case 53:
      case 54:
        setHoursText('Часа');
        break;
      case 1:
      case 21:
      case 31:
      case 41:
      case 51:
        setHoursText('Час');
        break;
      default:
        setHoursText('Часов');
    }
    switch(minutes){
      case 2:
      case 3:
      case 4:
      case 22:
      case 23:
      case 24:
      case 32:
      case 33:
      case 34:
      case 42:
      case 43:
      case 44:
      case 52:
      case 53:
      case 54:
        setMinutesText('Минуты');
        break;
      case 1:
      case 21:
      case 31:
      case 41:
      case 51:
        setMinutesText('Минута');
        break;
      default:
        setMinutesText('Минут');
    }
    switch(seconds){
      case 2:
      case 3:
      case 4:
      case 22:
      case 23:
      case 24:
      case 32:
      case 33:
      case 34:
      case 42:
      case 43:
      case 44:
      case 52:
      case 53:
      case 54:
        setSecondsText('Секунды');
        break;
      case 1:
      case 21:
      case 31:
      case 41:
      case 51:
        setSecondsText('Секунда');
        break;
      default:
        setSecondsText('Секунд');
    }
  }

  useEffect(() => {
      // Time calculations for days, hours, minutes and seconds
      if (interval === undefined) {
        setInterval(
          calcTimerData
        , 1000);
      }
      return () => {
        clearInterval(interval);
      }
  }, []);

  useEffect(()=>{
    renderSwitch()
  }, [seconds])


  return (
    <React.Fragment>
        <div className="timer__title">
          Сделай ресторан успешным
          <span className="timer__span">
            обучая персонал
          </span>
        </div>
        <div className="timer__sub-title">
          Подключи 1 ресторан<br/>
          второй регистрируй бесплатно
        </div>



        <div className="timer__text  timer__text--pretext">
          До конца акции
        </div>

        <div className="timer__essence">
          <div className="timer__item  timer__item--days">
            <div className="timer__num  timer__num--days">
              {days}
            </div>
            <div className="timer__num-label">
              {daysText}
            </div>
          </div>
          <div className="timer__item">
            <div className="timer__num  timer__num--hours">
              {hours}
            </div>
            <div className="timer__num-label">
              {hoursText}
            </div>
          </div>
          <div className="timer__item">
            <div className="timer__num  timer__num--minutes">
              {minutes}
            </div>
            <div className="timer__num-label">
              {minutesText}
            </div>
          </div>
          <div className="timer__item">
            <div className="timer__num  timer__num--seconds">
              {seconds}
            </div>
            <div className="timer__num-label">
              {secondsText}
            </div>
          </div>
        </div>

        <button onClick={()=>{props.setIsFormOpened(true)}} className="timer__button">
          <span>Подключайся</span>
        </button>
    </React.Fragment>
  )
}

export default Timer

import React, {useState, useEffect} from 'react'
import { Context } from './context'
import { hot } from 'react-hot-loader'
import { images } from './helpers'
import {
  Header,
  Apply,
  Timer,
  ThanksPopup,
} from './components';


const App = () => {

  const [isFormOpened, setIsFormOpened] = useState(false);
  const [isThanksMessageOpened, setIsThanksMessageOpened] = useState(false);

  return (
    <Context.Provider>
      <main
        className="main"style={{
          backgroundImage: `url(${images.header.bg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center top 80px',
          backgroundSize: '100%'
      }}>

        <Header />
        <Apply
          isFormOpened={isFormOpened}
          setIsFormOpened={setIsFormOpened}
          isThanksMessageOpened={isThanksMessageOpened}
          setIsThanksMessageOpened={setIsThanksMessageOpened}
         />
        <ThanksPopup isThanksMessageOpened={isThanksMessageOpened} setIsThanksMessageOpened={setIsThanksMessageOpened} />
        <Timer isFormOpened={isFormOpened} setIsFormOpened={setIsFormOpened} />

      </main>
    </Context.Provider>
  )
}

// export default  hot(module)(App)
export default  App
